import Swiper from 'swiper/bundle';
// import Swiper and modules styles
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
	loop: true,
	centeredSlides: true,
	autoplay: {
		delay: 4000,
		disableOnInteraction: false,
	},
	speed: 1000,
	
	//pagination: {
	//	el: '.swiper-pagination',
	//},
	
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});